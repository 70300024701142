<template>
    <div>
        <!-- <OEMCompoment/> -->
        <h3>กำลังเข้าสู่หน้าเว็บไซต์ OEM</h3>
    </div>
</template>
<script>
// import OEMCompoment from '../components/OEM/OEMCompoment.vue'
export default {
    
    name: 'OEM',
    components: {
        // OEMCompoment
    },
    created() {
        window.location.replace(`https://exam-manager.tlic.cmu.ac.th/`)
        // window.open(
        //   'https://exam-manager.tlic.cmu.ac.th/',
        //   '_blank' // <- This is what makes it open in a new window.
        // );
    },
    computed: {
    },
    data() {
    },
    methods: {}
  }
</script>